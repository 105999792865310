.entry-row {
  transition: background-color 0.3s;
}

.entry-row.highlighted {
  background-color: lightgreen;
}

.entry-row.highlighted:nth-child(even) {
  background-color: lightgreen;
}

.star {
  text-align: center;
}

.star-icon {
  cursor: pointer;
  transition: color 0.3s;
  color: var(--gray300);
}

.star-icon:hover {
  color: var(--orange400);
}

.star-icon.active {
  color: var(--abovePar);
}
