:root {
  --gray100: #f7fafc;
  --gray200: #edf2f7;
  --gray300: #e2e8f0;
  --gray400: #cbd5e0;
  --gray500: #a0aec0;
  --gray600: #718096;
  --gray700: #4a5568;
  --gray800: #2d3748;
  --gray900: #1a202c;
  --orange100: #fffaf0;
  --orange200: #feebc8;
  --orange300: #fbd38d;
  --orange400: #f6ad55;
  --orange500: #ed8936;
  --orange600: #dd6b20;
  --orange700: #c05621;
  --orange800: #9c4221;
  --orange900: #7b341e;
  --yellowDark: #f2d024;
  --belowPar: #e53e3e;
  --abovePar: #38a169;
  --tubeWidth: 1120px;
}
* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
}

body {
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
}

table {
  max-width: 1260px;
  width: 90vw;
  margin: 0 auto;
  overflow-x: auto;
  font-size: 14px;
  flex: 1;
  display: grid;
  border-collapse: collapse;
  grid-template-columns:
    58px /* size for the position column */
    30px /* size for the star column */
    minmax(120px, 2fr)
    minmax(120px, 2fr)
    minmax(120px, 2fr)
    minmax(120px, 2fr)
    minmax(120px, 2fr)
    minmax(120px, 2fr);
}

thead,
tbody,
tr {
  display: contents;
}

td.star,
th.star-header {
  text-align: center;
}

th,
td {
  padding: 0 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

th {
  padding: 15px;
  position: sticky;
  top: 0;
  background: var(--gray800);
  text-align: left;
  font-weight: normal;
  color: var(--gray100);
  position: relative;
}

th:last-child {
  border: 0;
}

td {
  padding-top: 5px;
  padding-bottom: 5px;
  color: #2d3748;
  border-top: 1px solid var(--gray300);
}

tr:nth-child(even) td {
  background: var(--gray200);
}

tr.favorite td {
  background: var(--yellowDark);
}

.entrycell {
  display: grid;
  grid-template-columns: 20px 1fr;
}

.entrycell-score {
  width: 24px;
  text-align: right;
}

.entrycell-player {
  padding-left: 12px;
}
